import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,5,2,3,4];

export const dictionary = {
		"/": [~7],
		"/home": [~22],
		"/join/[groupcode]": [~23,[5],[6]],
		"/[code]": [~8,[2]],
		"/[code]/grp/create": [~15,[2]],
		"/[code]/grp/join": [~16,[2]],
		"/[code]/grp/[id]": [~9,[2,3]],
		"/[code]/grp/[id]/chat": [~10,[2,3]],
		"/[code]/grp/[id]/pairings": [11,[2,3]],
		"/[code]/grp/[id]/rcqst": [~12,[2,3]],
		"/[code]/grp/[id]/sqst": [~13,[2,3]],
		"/[code]/grp/[id]/wshlst": [~14,[2,3]],
		"/[code]/usr": [~17,[2]],
		"/[code]/wshlst/[id]": [~18,[2,4]],
		"/[code]/wshlst/[id]/assign": [~20,[2,4]],
		"/[code]/wshlst/[id]/createEntry": [~21,[2,4]],
		"/[code]/wshlst/[id]/[entryId]/edit": [~19,[2,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';